import _ from "lodash";
import React, { useEffect } from "react";
import {
  PencilIcon,
  CogIcon,
  LightBulbIcon,
  LockClosedIcon,
  CurrencyDollarIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

import { Layout, QuoteForm, SEO } from "../components";
import { useSeoProps } from "../components/useSeoProps";
import { capture } from "../common/analytics";
import BrightLogoPositive from "../images/logos/bright_logo_positive.svg";
import ReactMarkdown from "react-markdown";

const benefits = [
  {
    title: "Las mejores opciones opciones de financiamiento",
    description:
      "Obtén todos los beneficios de instalar paneles solares en tu techo sin descapitalizarte.",
    icon: CurrencyDollarIcon,
  },
  {
    title: "Diseño Personalizado",
    description:
      "Sugerimos el tamaño y la ubicación de tu sistema fotovoltaico de acuerdo a tus necesidades energéticas, la cantidad de sol y el tamaño de tu techo.",
    icon: PencilIcon,
  },
  {
    title: "Garantía de producción",
    description:
      "La garantía de producción más alta del mercado con sistema de reembolso por contrato.",
    icon: LockClosedIcon,
  },
  {
    title: "Trámites ante CFE",
    description:
      "Desde el papeleo hasta los permisos, nos encargaremos de todos los aspectos para la instalación de tu sistema.",
    icon: LightBulbIcon,
  },
  {
    title: "Mantenimiento",
    description:
      "Servicio de mantenimiento preventivo y correctivo para asegurarnos que el equipo siempre funcione al 100%.",
    icon: CogIcon,
  },
];

type TProps = {
  pageContext?: {
    channelPartnerId: string;
    slug: string;
    name: string;
    logo: {
      formats: {
        small: {
          url: string;
        };
      };
      url?: string;
      id?: number;
    };
    about: string;
  };
};

const Benefits = ({}) => {
  return (
    <>
      {benefits.map((item, index) => (
        <div key={index} className="flex flex-row space-x-6">
          <div className="flex flex-shrink-0 items-center justify-center h-14 w-14 text-gray-700">
            <item.icon className="h-8 w-8" />
          </div>
          <div>
            <div className="text-md font-medium text-gray-900 flex-shrink-0	text-left">
              {item.title}
            </div>
            <div className="mt-2 text-gray-500 text-left">
              {item.description}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const ChannelPartnerQuotePage = ({ pageContext }: TProps) => {
  const { slug } = pageContext;
  useEffect(() => {
    capture("channel_partner_landing_page_viewed", { slug });
  }, [slug]);

  const metaPage = "cotizar";
  const metaTitle = "Solicita tu propuesta 100% personalizada sin costo";
  const metaDescription =
    "Solicita una cotización totalmente gratis, ¿Estás listo para hacer el cambio a energía solar?";

  const seoProps = useSeoProps(metaPage, metaTitle, metaDescription);

  // Strapi does not generate different formats of images if their breakpoints
  // are not exceeded. So a 500 x 500 pixel image (or smaller) will not have a
  // small format url. So we'll just use the logo url.
  // https://github.com/strapi/strapi/issues/6130#issuecomment-630254373
  let channelPartnerLogoUrl: string | null = null;
  if (pageContext?.logo?.id) {
    if (pageContext.logo.formats.small?.url) {
      channelPartnerLogoUrl = pageContext.logo.formats.small.url;
    } else {
      channelPartnerLogoUrl = pageContext.logo.url;
    }
  }

  return (
    <Layout hideNav={true}>
      <SEO {...seoProps} />
      <div className="flex w-full flex-col md:flex-row mx-auto max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div className="w-full py-4 px-8 md:px-4 flex flex-col items-center">
          {channelPartnerLogoUrl && (
            <div className="grid grid-cols-3 gap-4 md:w-1/2 w-11/12 justify-items-center align-middle content-center items-center">
              <div>
                <img
                  src={channelPartnerLogoUrl}
                  width={125}
                  height={125}
                  className="items-center align-middle"
                ></img>
              </div>
              <div>
                <PlusIcon className="h-6 w-6" />
              </div>
              <div className="inline-block align-middle text-center items-center content-center">
                <img
                  className="items-center align-middle"
                  src={BrightLogoPositive}
                  width={125}
                  height={125}
                />
              </div>
            </div>
          )}
          <div className="font-bold text-xl text-center italic">
            {pageContext.name + " en asociación con Bright"}
          </div>
          <div className="w-11/12 mt-5 font-base text-center border rounded-lg flex flex-col items-center">
            <p className="w-11/12 text-xl md:text-3xl font-bold text-center uppercase text-green-600 py-2">
              Energía solar para tu casa, negocio o industria sin
              descapitalizarte
            </p>

            <p className="w-11/12 text-md py-5">
              En un mundo donde el ahorro y la sostenibilidad van de la mano, te
              invitamos a descubrir una forma inteligente y eficiente de tomar
              el control de tus gastos energéticos mientras contribuyes al
              bienestar de nuestro planeta.
            </p>
            <p className="w-11/12 text-md ">
              ¿Listo para dar el primer paso hacia un futuro más brillante y
              sostenible?{" "}
              <b>
                Llena el siguiente formulario y obtén una propuesta preliminar
                en minutos:
              </b>
            </p>

            <div className="flex flex-col justify-start items-start py-5 text-left">
              <QuoteForm
                form="gatsby_cotizar"
                interactiveProposalVariant="software"
                channelPartnerData={{
                  channelPartnerId: pageContext.channelPartnerId,
                  slug: pageContext.slug,
                }}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex">
                <div className="flex-1">
                  <div className="lg:max-w-xl mx-auto bg-gray-50 px-1.5 py-2 sm:px-8 sm:py-8 rounded-lg h-full">
                    <h2 className="text-lg font-bold text-center uppercase">
                      Beneficios
                    </h2>
                    <div className="flex flex-col space-y-6 mt-10">
                      <Benefits />
                    </div>
                  </div>
                </div>
              </div>
              {/*Do not display if there's no channel partner "about" filled*/}
              {pageContext.about && (
                <div className="flex">
                  <div className="flex-1">
                    <div className="lg:max-w-xl mx-auto bg-gray-50 px-1.5 py-2 sm:px-8 sm:py-8 rounded-lg h-full">
                      <h2 className="text-lg font-bold text-center uppercase">
                        {"Sobre " + pageContext.name}
                      </h2>
                      <div className="flex flex-col space-y-6 mt-10">
                        <ReactMarkdown>{pageContext.about}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChannelPartnerQuotePage;
